import { ERRORS, appendParamsToEndpoint } from '~/helpers/general';
import { USER_LOGOUT } from '~/store/reducers/session';
import reduxStore from '~/store/setupStore';

// Do not use a const for api_root_url value (this line is used by install_form-builder.sh)
export const api_root_url = 'https://labodev.label-emmaus.co/labo/api';

function interceptFailedRequest(response) {
  if (String(response.status) === ERRORS.FORBIDDEN.CODE) {
    reduxStore.dispatch({ type: USER_LOGOUT });
    throw Error('logged-out');
  } else {
    return response;
  }
}

function fetchJSON(url, settings = {}) {
  let basic_settings = {
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
  };
  let final_settings = settings.headers
    ? {
        ...basic_settings,
        headers: {
          ...basic_settings.headers,
          ...settings.headers,
        },
      }
    : { ...basic_settings, ...settings };

  return fetch(url, final_settings)
    .then(interceptFailedRequest)
    .then((data) => data.json());
}

function fetchJSONFromLabelApi(path, settings = {}) {
  let basic_settings = {
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };
  // NOTE: For sending form data, if we set the content-type by hand (as multipart/form-data)
  // then the boundary parameter will be missing.
  // to avoid this we simply delete the content-type if we detect that the body
  // is an instanceof FormData thus letting the fetch API setting the appropriate
  // header

  if (settings.body instanceof FormData) {
    delete basic_settings.headers['Content-Type'];
  }

  // we do this so that if the settings contains additional headers
  // they will be overidden by the basic_settings

  let final_settings = settings.headers
    ? {
        ...basic_settings,
        headers: {
          ...basic_settings.headers,
          ...settings.headers,
        },
      }
    : { ...basic_settings, ...settings };

  return fetch(path, final_settings)
    .then(interceptFailedRequest)
    .then((data) => data.json());
}

export const api = {
  get(path, settings = {}) {
    if (path.startsWith('http') || path.startsWith('https')) {
      return fetchJSON(path, { method: 'GET', ...settings });
    }

    return fetchJSONFromLabelApi(api_root_url + path, {
      method: 'GET',
      ...settings,
    });
  },
  patch(path, settings = {}) {
    return fetchJSONFromLabelApi(`${api_root_url}${path}`, {
      method: 'PATCH',
      ...settings,
    });
  },
  post(path, settings = {}, params = {}) {
    let final_settings = { method: 'POST', ...settings };
    let url = `${api_root_url}${path}`;

    if (params) {
      url = appendParamsToEndpoint(url, params);
    }
    return fetchJSONFromLabelApi(url, final_settings);
  },
};
