import { Flex, Spin, Table, Tag } from 'antd';
import { format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import AlertBasicServerError from '~/components/shared/AlertBasicServerError/AlertBasicServerError';
import CustomPagination from '~/components/shared/CustomPagination/CustomPagination';
import CustomSelect from '~/components/shared/CustomSelect/CustomSelect';
import { RoutingUrl, getImageUrl } from '~/helpers/general';
import { SEARCH_PARAMETERS_API_IDS, colorStatusMapping } from '~/helpers/order/catalogue';
import { useOrders } from '~/hooks/useOrders';

import './OrdersTable.scss';

const renderImage = (items) => {
  if (!items || !items.length || !items[0]?.item_image_url) return <div className='ad-img' />;
  return <img className='ad-img' src={getImageUrl(items[0].item_image_url, 100, 'crop')} alt='Photo apercu' />;
};

const renderIdNumber = (order) => <p className='body1'>{order.id_number}</p>;

const renderCustomer = (user) => <p className='body1'>{user.display_name}</p>;

const renderAmount = (amount_vat_included) => <p className='body1'>{amount_vat_included}€</p>;

const renderNbItems = (items) => <p className='body1'>{items.length}</p>;

const renderCreatedOn = (created_on) => (
  <p className='body1' title={format(created_on, 'DD.MM.YYYY HH:mm:ss')}>
    {format(created_on, 'DD.MM.YY')}
  </p>
);

const renderStatus = (status_localized) => (
  <Tag className='light capitalize-first-letter' color={colorStatusMapping[status_localized]}>
    {status_localized}
  </Tag>
);

const columns = [
  {
    title: 'Image',
    key: 'image',
    dataIndex: 'items',
    className: 'image-cell',
    render: renderImage,
  },
  {
    title: 'N°commande',
    key: 'id_number',
    dataIndex: 'order',
    render: renderIdNumber,
  },
  {
    title: 'Client',
    key: 'customer',
    dataIndex: 'user',
    render: renderCustomer,
  },
  {
    title: 'Montant TTC',
    key: 'amount',
    dataIndex: 'amount_vat_included',
    render: renderAmount,
    width: 120,
  },
  {
    title: 'Articles',
    key: 'items',
    dataIndex: 'items',
    render: renderNbItems,
    width: 100,
  },
  {
    title: 'Date de création',
    key: 'created_on',
    dataIndex: 'created_on',
    render: renderCreatedOn,
    width: 150,
  },
  {
    title: 'Statut',
    key: 'status',
    dataIndex: 'status_localized',
    render: renderStatus,
  },
].map((col) => ({
  ...col,
  title: <div className='title-cell'>{col.title}</div>,
  render: (data, order) => (
    <Link
      className='link-edition-cell'
      to={{
        pathname: RoutingUrl.orderDetails([order.id]),
        state: {
          fromCatalogue: window.location.search,
        },
      }}
    >
      {col.render(data, order)}
    </Link>
  ),
}));

export default function OrdersTable({ activeMerchantId, params, changeParams }) {
  const { isLoading, data } = useOrders(activeMerchantId, params);
  if (isLoading) {
    return (
      <Flex className='orders-table-loading-wrapper' justify='center' align='center'>
        <Spin size='large' />
      </Flex>
    );
  }

  if (!data) {
    return <AlertBasicServerError />;
  }

  // Extract data from responses
  const { meta, objects: orders } = data;
  const { total_count: total } = meta;

  const numberOrdersText = `${orders.length} commande${orders.length > 1 ? 's' : ''} sur ${total}`;
  const currentPage = params[SEARCH_PARAMETERS_API_IDS.PAGE] ? parseInt(params[SEARCH_PARAMETERS_API_IDS.PAGE]) : 1;
  const limit = params[SEARCH_PARAMETERS_API_IDS.LIMIT] ? parseInt(params[SEARCH_PARAMETERS_API_IDS.LIMIT]) : 20;

  return (
    <>
      <p className='body1'>{numberOrdersText}</p>
      <Table className='orders-table' scroll={{ x: 'max-content', y: '70vh' }} pagination={false} dataSource={orders} columns={columns} rowKey='id' />
      <div className='pagination-wrapper'>
        <CustomSelect
          className='orders-select-limit'
          options={[
            { value: 10, label: '10 / page' },
            { value: 20, label: '20 / page' },
            { value: 50, label: '50 / page' },
            { value: 100, label: '100 / page' },
          ]}
          value={limit}
          onChange={(value) =>
            changeParams({
              ...params,
              [SEARCH_PARAMETERS_API_IDS.PAGE]: 1,
              [SEARCH_PARAMETERS_API_IDS.LIMIT]: value,
            })
          }
        />
        <CustomPagination
          className='orders-pagination'
          total={total}
          defaultCurrent={1}
          defaultPageSize={50}
          current={currentPage}
          pageSize={limit}
          onChange={(page) => {
            changeParams({
              ...params,
              [SEARCH_PARAMETERS_API_IDS.PAGE]: page,
            });
          }}
          showTotal={false}
          showSizeChanger={false}
          showLessItems
        />
      </div>
    </>
  );
}
