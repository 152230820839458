import { useQueries, useQuery } from '@tanstack/react-query';
import { paramsToURL } from '~/components/screens/OrdersCatalogue/OrdersCatalogue';
import { OPEN_INCIDENT_STATUS, SEARCH_PARAMETERS_API_IDS, STATUS_API_IDS } from '~/helpers/order/catalogue';
import { api } from '~/services/api';

import { treatApiError } from './utils';

export const useOrders = (merchantId, params) => {
  const paramsURL = paramsToURL(params);
  return useQuery({
    queryKey: ['orders', params, merchantId],
    queryFn: () =>
      api.get(`/${merchantId}/izberg/orders/${paramsURL}`).then((res) => {
        if (res.success) {
          return res.data;
        }
        treatApiError(res);
      }),
  });
};

export const useOrderToConfirmCount = (merchantId) => {
  return useQuery({
    queryKey: ['ordersAuthorizedCount', merchantId],
    queryFn: () =>
      api
        .get(`/${merchantId}/izberg/orders/?${SEARCH_PARAMETERS_API_IDS.STATUS}=${STATUS_API_IDS.AUTHORIZED}&limit=1&meta_only=true&merchant=${merchantId}`)
        .then((res) => {
          if (res.success) {
            return res.data.meta.total_count;
          }
          treatApiError(res);
        }),
  });
};

export const useOrdersCount = (merchantId) => {
  const statusToFetch = [
    {
      key: 'ordersAuthorizedCount',
      query: `${SEARCH_PARAMETERS_API_IDS.STATUS}=${STATUS_API_IDS.AUTHORIZED}`,
    },
    {
      key: 'ordersConfirmedCount',
      query: `${SEARCH_PARAMETERS_API_IDS.STATUS}=${STATUS_API_IDS.CONFIRMED}`,
    },
    {
      key: 'ordersOpenIncidentsCount',
      query: `${SEARCH_PARAMETERS_API_IDS.INCIDENT_STATUS}=${OPEN_INCIDENT_STATUS}`,
    },
  ];

  const queries = statusToFetch.map((status) => ({
    queryKey: [status.key, merchantId],
    queryFn: () =>
      api.get(`/${merchantId}/izberg/orders/?${status.query}&limit=1&meta_only=true`).then((res) => {
        if (res.success) {
          return res.data.meta.total_count;
        }
        treatApiError(res);
      }),
  }));

  return useQueries({
    queries: queries,
    combine: (results) => {
      const [ordersAuthorizedCount, ordersConfirmedCount, ordersOpenIncidentsCount] = results;
      return {
        data: {
          ordersAuthorizedCount: ordersAuthorizedCount.data,
          ordersConfirmedCount: ordersConfirmedCount.data,
          ordersOpenIncidentsCount: ordersOpenIncidentsCount.data,
        },
        pending: results.some((result) => result.isPending),
        error: results.find((result) => result.isError)?.error,
      };
    },
  });
};
